import React from 'react';
import { useCookies } from 'react-cookie';

import { Container, Paragraph, AcknowledgeContainer, Button, Icon } from './style'


const CookieBanner = () => {
  const [cookies, setCookie] = useCookies(['shouldHideCookieBanner']);

  const handleAcknowledgement = () => {
    let currentDate = new Date();
    let newDate = new Date(currentDate);
    newDate.setFullYear(currentDate.getFullYear() + 1);
    setCookie('shouldHideCookieBanner', true, { expires: newDate });
  }

  if (cookies.shouldHideCookieBanner) {
    return null;
  }

  return (
    <Container>
      <Paragraph>
        This site uses cookies to make your browsing experience better. We also use google analytics for tracking purposes and we are confident that it is secure.
      </Paragraph>
      <AcknowledgeContainer>
        <Button onClick={handleAcknowledgement}>
          ok!
        </Button>
        <Icon>
          <img src="/assets/cookie_icon.svg" alt="santa eating cookie" />
        </Icon>
      </AcknowledgeContainer>
    </Container>
  )
}

export default CookieBanner;