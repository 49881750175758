import { NavItemConfig } from '../../types/config'

export const navItemsConfig: NavItemConfig[] = [
  {
    iconPath: "/assets/question_mark_icon.svg",
    alt: "question mark",
    text: "classic",
    primaryColorLetters: [3],
    link: "/classic"
  },
  {
    iconPath: "/assets/note_icon.svg",
    alt: "note icon",
    text: "sound",
    primaryColorLetters: [4],
    link: "/sound"
  },
  {
    iconPath: "/assets/image_icon.svg",
    alt: "image icon",
    text: "image",
    primaryColorLetters: [1],
    link: "/image"
  },
  {
    iconPath: "/assets/a_icon.svg",
    alt: "a letter",
    text: "description",
    primaryColorLetters: [2, 8],
    link: "/description"
  },
]