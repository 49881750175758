import styled, { keyframes } from "styled-components";
import { theme } from "../../styles/Theme";

interface AttributeValueProps {
  isCorrect: boolean;
}

const animationSpeed = 0.5;

const loadAttribute = keyframes`
 0% { transform: rotate3d(0, 1, 0, 90deg); opacity: 1}
 100% { transform: rotate3d(0); opacity: 1 }
`;

export const Container = styled.div`
  display: flex;
  align-items: flex-end;
`;

export const AttributesContainer = styled.div`
  display: flex;
  align-items: flex-end;
  font-family: "Supercell-Magic";
  margin-top: 2rem;

  @media (max-width: 700px) {
    width: 40rem;
    overflow-x: auto;
    margin-left: 1rem;

    ::-webkit-scrollbar {
      width: 2px;
      height: 2px;
    }

    ::-webkit-scrollbar-track {
      background: #f1f1f1;
    }

    ::-webkit-scrollbar-thumb {
      background: ${theme.colors.primary};
    }

    ::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
  }
`;

export const HeroImageContainer = styled.div`
  img {
    width: 10rem;
  }
`;

export const AttributeBox = styled.div`
  margin: 0.5rem;
`;

export const AttributeName = styled.h4`
  color: white;
  font-weight: 500;
  font-size: 1.2rem;
  text-align: center;
  margin-bottom: 0.4rem;
`;

export const AttributeValue = styled.div<AttributeValueProps>`
  font-weight: 500;
  background: ${(props) =>
    props.isCorrect ? theme.colors.green : theme.colors.red};
  height: 10rem;
  width: 10rem;
  border: 2px solid ${theme.colors.yellow};
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 1.4rem;
  text-align: center;
  line-height: 1.3;
`;

export const AttributeValueAnimated = styled(AttributeValue)`
  opacity: 0;
  animation: ${loadAttribute} ${animationSpeed}s ease-in forwards;
`