import styled from "styled-components";

import { theme } from '../../styles/Theme';

export const Container = styled.div`
  display: flex;
  align-items: center;
  gap: .5rem;
`

export const TrophiesIcon = styled.div`
  img {
    width: 4rem;
  }
`

export const TrophiesValue = styled.h5`
  color: ${theme.colors.white};
  font-family: "Supercell-Magic";
  font-size: 2rem;
  letter-spacing: 2px;
  background-color: rgba(0,0,0,0.5);
  padding: 1rem 1rem;
  border-radius: 1rem;
`