import React from "react";

import { GameProvider } from "../Providers";

export const GameWrapper = (
  GameComponent: React.ComponentType<any>,
  type: string,
) => {
  return (
    <GameProvider type={type}>
      <GameComponent />
    </GameProvider>
  );
};
