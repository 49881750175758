import React, { useContext, useEffect, useRef } from "react";

import Layer from "../Layer/Layer";
import { GameInfoBox } from "../GameInfo";
import { GameInput } from "../GameInfo";
import GameVictory from "../GameVictory";
import Delayed from "../Delayed";

import { Container } from "./style";

import { launchConfetti } from "../../utils/launchConfetti";

import { CardsContext, GameContext } from "../Providers";
import { Card, NextGameType } from "../../types/types";
import { BoxOptions } from "../GameInfo/GameInfoBox";
import AdBottomFixed from "../GoogleAd/AdBottomFixed";

import { MTop } from "../../styles/Global";

interface Props {
  children: React.ReactNode;
  gameDescription: string;
  isCardCorrect: (card: Card) => boolean;
  boxOptions?: BoxOptions;
  nextGame?: NextGameType;
}

export const Game = ({
  gameDescription,
  isCardCorrect,
  children,
  boxOptions,
  nextGame,
}: Props) => {
  const { cards } = useContext(CardsContext);
  const { correctAnswer, game, delayTime } = useContext(GameContext);

  const bottomRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (correctAnswer && !game.playerFinishedToday) {
      setTimeout(() => {
        handleScrollToBottom();
        launchConfetti();
      }, delayTime + 100);
    }
  }, [correctAnswer]);

  const handleScrollToBottom = () => {
    if (bottomRef.current) {
      bottomRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <Layer>
      <Container>
        <GameInfoBox gameDescription={gameDescription} options={boxOptions} />
        <GameInput
          placeholder={"Type card's name"}
          cards={cards}
          isCardCorrect={isCardCorrect}
        />
        <Container>
          {children}
          <MTop size="md" />
          {correctAnswer && (
            <Delayed delayTime={game.playerFinishedToday ? 0 : delayTime}>
              <GameVictory
                cardImage={correctAnswer.image.url}
                cardName={correctAnswer.name}
                nextGame={nextGame}
              />
              <div ref={bottomRef} />
            </Delayed>
          )}
        </Container>
      </Container>
      <AdBottomFixed />
    </Layer>
  );
};
