import styled, { keyframes } from "styled-components";

import { theme } from "../../styles/Theme";

const loadVictoryBox = keyframes`
 0% { opacity:0 }
 100% { opacity: 1 }
`;

export const Box = styled.div`
  font-family: "Supercell-Magic";
  box-sizing: border-box;
  padding: 4rem 2rem;
  border: 5px solid ${theme.colors.primary};
  background: linear-gradient(to bottom, ${theme.colors.primary}, #E7AB0D);
  border-radius: 1rem;
  width: 40rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  animation: ${loadVictoryBox} 0.5s ease-in forwards;
  box-shadow: 0px -3px 15px 0px rgba(0, 0, 0, 1);
  /* color: #2E6697; */
`


export const Title = styled.h3`
  font-size: 4rem;
  /* color: ${theme.colors.green}; */
`

export const Hero = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 2rem;
`

export const HeroImage = styled.div`
  img {
    width: 20rem;
  }
`

export const HeroName = styled.div`
  font-size: 1.6rem;
`

export const NextGameInfo = styled.div`
  margin-top: 4rem;
  font-size: 1.4rem;
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const NextGameLink = styled.div`
  margin-top: 2.5rem;

  a {
    display: inline-block;
    cursor: pointer;
    background: black;
    color: white;
    padding: 1.2rem 3rem;
    border-radius: 1rem;
    box-shadow: 0 2px 5px rgba(0, 0, 0, .6);
    font-size: 1.5rem;
    /* border: 3px solid ${theme.colors.primary}; */
  }

  &:hover {
    transform: scale(1.02);
  }
`