import React, { useContext } from "react";

import { GameContext } from "../Providers";

import {
  Container,
  InfoBox,
  InfoBoxText,
  Box,
  Paragraph,
  BlurredImage,
} from "./styles";
import { MTop } from "../../styles/Global";
import { FileType } from "../../types/types";

import SoundPlayer from "./SoundPlayer";

export interface BoxOptions {
  type: "audio" | "image" | "description";
  audioFile?: FileType;
  imageFile?: FileType;
  description?: string;
}

interface GameInfoBoxProps {
  gameDescription: string;
  options?: BoxOptions;
}

const GameInfoBox = ({ gameDescription, options }: GameInfoBoxProps) => {
  const { submittedCards, correctAnswer } = useContext(GameContext);

  return (
    <MTop size="md">
      <Container>
        <Box>
          <InfoBox>
            <InfoBoxText>{gameDescription}</InfoBoxText>
            {options && options.type === "audio" && options.audioFile ? (
              <SoundPlayer audio={options.audioFile} />
            ) : options &&
              options.type === "description" &&
              options.description ? (
              <Paragraph>"{options.description}"</Paragraph>
            ) : options && options.type === "image" && options.imageFile ? (
              <BlurredImage blurAmount={correctAnswer ? 0 : 17 - submittedCards.length}>
                <img src={options.imageFile.url} alt="" />
              </BlurredImage>
            ) : null}
          </InfoBox>
        </Box>
      </Container>
    </MTop>
  );
};

export default GameInfoBox;
