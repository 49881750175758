import React, { createContext, useState, useEffect, useContext } from "react";

import { GameContextInterface, CardWithInitial } from "../types";

import { Card } from "../../../types/types";

import { GameType } from "../types";

import Fetcher from "../../../utils/Fetcher";

import Layer from "../../Layer/Layer";
import { SwordSpinner } from "../../Spinner/SwordSpinner";

import { CardsContext } from "../index";

const classicDelayTime = 3500;
const regularDelayTime = 600;

export const GameContext = createContext<GameContextInterface>({
  submittedCards: [],
  setSubmittedCards: () => {},
  game: {
    hashedAnswer: "",
    wrongAnswers: [],
    gameTypeId: 0,
    playerFinishedToday: false,
  },
  correctAnswer: null,
  setCorrectAnswer: () => {},
  delayTime: 0,
});

export const GameProvider = ({
  type,
  children,
}: {
  type: string;
  children: React.ReactNode;
}) => {
  const [submittedCards, setSubmittedCards] = useState<CardWithInitial[]>([]);

  const [delayTime, setDelayTime] = useState<number>(regularDelayTime);
  const { isLoading: isLoadingCards } = useContext(CardsContext);

  const [correctAnswer, setCorrectAnswer] = useState<Card | null>(null);

  const [game, setGame] = useState<GameType | null>(null);

  useEffect(() => {
    const fetchGame = async () => {
      const game = await Fetcher.fetchData<GameType>(
        `/game/${type}`
      );
      const submittedCards: CardWithInitial[] = game.wrongAnswers.map(
        (card) => ({ ...card, isInitial: true })
      );

      if (game.unhashedAnswer) {
        setCorrectAnswer(game.unhashedAnswer);
        submittedCards.unshift({ ...game.unhashedAnswer, isInitial: true });
      }

      if (game.gameTypeId === 1) {
        setDelayTime(classicDelayTime);
      }

      setSubmittedCards(submittedCards);
      setGame(game);
    };

    fetchGame();

    return () => resetGameState();
  }, [type]);

  const resetGameState = () => {
    setSubmittedCards([]);
    setCorrectAnswer(null);
    setGame(null);
    setDelayTime(regularDelayTime);
  }

  if (!game || isLoadingCards) {
    return (
      <Layer>
        <div style={{ marginTop: "4rem" }}>
          <SwordSpinner />
        </div>
      </Layer>
    );
  }

  return (
    <GameContext.Provider
      value={{
        submittedCards,
        setSubmittedCards,
        game,
        correctAnswer,
        setCorrectAnswer,
        delayTime
      }}
    >
      {children}
    </GameContext.Provider>
  );
};
