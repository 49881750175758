import React from "react";

import { Container, StyledTitle, Text } from "./styles";

import { Link } from 'react-router-dom';

interface TitleProps {
  centered?: boolean;
  margin?: "basic" | "none";
}

const Title = ({ centered, margin = "basic" }: TitleProps) => {
  return (
    <Container centered={centered} margin={margin}>
      <Link to="/">
        <StyledTitle>
          <Text>Clash</Text>
          <Text primary>dle</Text>
        </StyledTitle>
      </Link>
    </Container>
  );
};

export default Title;
