import styled, { keyframes } from "styled-components"; 
import { theme } from '../../styles/Theme'

interface AttributeValueProps {
  isCorrect: boolean;
}

const loadBox = keyframes`
 0% { opacity:0 }
 100% { opacity: 1 }
`;

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Supercell-Magic";
  margin-top: 2rem;
`;

export const Box = styled.div<AttributeValueProps>`
  width: 33rem;
  background: ${props => props.isCorrect ? theme.colors.green : theme.colors.red};
  border: 2px solid ${theme.colors.yellow};
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem;
`

export const BoxAnimated = styled(Box)`
  animation: ${loadBox} 0.5s ease-in forwards;
`

export const HeroImageContainer = styled.div`
  width: 30%;
  img {
    width: 10rem;
  }
`

export const HeroName = styled.h4`
  width: 70%;
  color: white;
  font-weight: 500;
  font-size: 2.5rem;
  letter-spacing: 1px;
  margin-left: 2rem;
  text-align: center;
  line-height: 1.3;
`