import React from "react";

import { StyledSpinner, SpinnerDot } from "./style";

export const Spinner = () => {
  return (
    <StyledSpinner>
      <SpinnerDot />
      <SpinnerDot />
      <SpinnerDot />
      <SpinnerDot />
      <SpinnerDot />
      <SpinnerDot />
    </StyledSpinner>
  );
};
