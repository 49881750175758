import styled from "styled-components";

import { theme } from "../../styles/Theme";

interface BoxProps {
  visible: boolean;
}

export const Container = styled.div`
  display: flex;
  gap: 1rem;
`

export const Icon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 4.5rem;
  height: 4.5rem;
  background: ${theme.colors.white};
  border: 4px solid ${theme.colors.primary};
  border-radius: 50%;
  padding: 1.2rem;
  cursor: pointer;

  @media (max-width: 900px) {
    width: 6rem;
    height: 6rem;
  }

`

export const Box = styled.div<BoxProps>`
  display: ${(props) => props.visible ? "block" : "none"};
  /* height: 78%; */
  width: 70rem;
  background: ${theme.colors.darkBlue};
  position: fixed;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
  border: 5px solid ${theme.colors.primary};
  border-radius: 4rem;

  a {
    color: ${theme.colors.primary};
  }

  @media (max-width: 600px) {
    width: 60rem;
  }

  @media (max-width: 400px) {
    width: 45rem;
  }

`

export const InfoContainer = styled.div`
  font-family: "Supercell-Magic";
  padding: 5rem;
`;

export const Title = styled.h3`
  color: ${theme.colors.white};
  font-size: 5rem;
`;

export const Text = styled.p`
  color: ${theme.colors.white};
  font-size: 1.7rem;
  line-height: 1.4;

  @media (max-width: 400px) {
    font-size: 1.4rem;
  }

`

export const About = styled.div`
  margin-top: 2rem;
`;

export const Privacy = styled.div`
  margin-top: 4rem;
`;

export const CloseIcon = styled.div`
  position: absolute;
  right: 2rem;
  top: 1rem;
  color: white;
  font-size: 4rem;
  cursor: pointer;
`