import React from 'react';

import { Icon } from './style';

interface Props {
  path: string;
  onClick: () => void;
}

const InfoIcon = ({ path, onClick }: Props) => {
  return (
    <Icon onClick={onClick}>
      <img src={path} alt="" />
    </Icon>
  )
}

export default InfoIcon;