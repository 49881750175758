import React from "react";
import { unCamelCase } from "../../utils/unCamelCase";
import { capitalizeFirstLetter } from '../../utils/capitalizeFirstLetter';

import {
  Container,
  AttributesContainer,
  HeroImageContainer,
  AttributeBox,
  AttributeName,
  AttributeValue,
  AttributeValueAnimated,
} from "./styles";

import { MappedBoxAttributes } from "../../types/types";

interface Props {
  imageSrc: string;
  attributes: MappedBoxAttributes[];
  isInitial: boolean;
}

const HeroBoxClassic = ({ imageSrc, attributes, isInitial }: Props) => {
  return (
    <Container>
      <HeroImageContainer>
        <img src={imageSrc} alt="card" />
      </HeroImageContainer>
      <AttributesContainer>
        {attributes.map((attribute, i) => (
          <AttributeBox key={attribute.name}>
            <AttributeName>{unCamelCase(attribute.name)}</AttributeName>
            {isInitial ? (
              <AttributeValue
                isCorrect={attribute.isCorrect}
              >
                {capitalizeFirstLetter(attribute.value)}
              </AttributeValue>
            ) : (
              <AttributeValueAnimated
                isCorrect={attribute.isCorrect}
                style={{ animationDelay: `${i * 0.5}s` }}
              >
                {capitalizeFirstLetter(attribute.value)}
              </AttributeValueAnimated>
            )}
          </AttributeBox>
        ))}
      </AttributesContainer>
    </Container>
  );
};

export default HeroBoxClassic;
