import React, { useContext, useEffect, useState } from "react";

import { encryptXOR } from "../../../utils/encryptXor";

import { GameWrapper } from "../GameWrapper";
import { Game } from "../Game";
import HeroBoxClassic from "../../HeroBoxClassic";

import { GameContext } from "../../Providers";

import { Card, AttributesEnum } from "../../../types/types";

const ClassicGameComponent = () => {
  const { submittedCards, game } = useContext(GameContext);

  const mapAttributes = (card: Card) => {
    const attributeMap = Object.keys(card.attributes).map((attributeName) => {
      const attributeValue = card.attributes[attributeName as AttributesEnum].value;
      const isAttributeCorrect = checkIfAttributeIsCorrect(attributeName, attributeValue);
      return {
        name: attributeName,
        value: attributeValue,
        isCorrect: isAttributeCorrect,
      };
    });

    return attributeMap;
  };

  const checkIfAttributeIsCorrect = (
    attributeName: string,
    attributeValue: string
  ) => {
    if (!("hashedAttributes" in game)) return false;
    const secret = process.env.REACT_APP_XOR_ENCRYPTION_SECRET as string;
    const encryptedValue = encryptXOR(attributeValue, secret);
    return (
      encryptedValue === game.hashedAttributes[attributeName as AttributesEnum]
    );
  };

  const isCardCorrect = (card: Card): boolean => {
    let isCorrect = true;

    Object.keys(card.attributes).forEach((attributeName) => {
      const attributeValue = card.attributes[attributeName as AttributesEnum].value;
      const isAttributeCorrect = checkIfAttributeIsCorrect(attributeName, attributeValue);

      if (!isAttributeCorrect) {
        isCorrect = false;
      }
      
    });

    //attributes are the same but we still should check if its correct card
    if (isCorrect) {
      const secret = process.env.REACT_APP_XOR_ENCRYPTION_SECRET as string;
      const encryptedCardName = encryptXOR(card.name, secret);
      isCorrect = encryptedCardName === game.hashedAnswer;
    }

    return isCorrect;
  }

  return (
    <Game gameDescription="Guess today's clash royale card!" isCardCorrect={isCardCorrect} nextGame={{ name: "Sound Game", path: "/sound" }}>
      {submittedCards.map((card) => (
        <HeroBoxClassic
          key={card.id}
          imageSrc={card.image.url}
          attributes={mapAttributes(card)}
          isInitial={!!card.isInitial}
        />
      ))}
    </Game>
  );
};

export const ClassicGame = GameWrapper(ClassicGameComponent, "classic");
