import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "./fonts/supercell-magic.ttf";
import "./index.css";
import ReactGA from 'react-ga4';
import { BrowserRouter } from "react-router-dom";
import { CookiesProvider } from 'react-cookie';

ReactGA.initialize(process.env.REACT_APP_GA_MEASUREMENT_ID as string || 'default');

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(<CookiesProvider><BrowserRouter><App /></BrowserRouter></CookiesProvider>);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
