import React from "react";

import { Box, Title, Text, About, Privacy, InfoContainer, CloseIcon } from "./style";

import { Link } from "react-router-dom";

interface Props {
  visible: boolean;
  toggleInfoBox: () => void;
}

const InfoBox = ({ visible, toggleInfoBox }: Props) => {
  return (
    <Box visible={visible}>
      <InfoContainer>
        <CloseIcon onClick={toggleInfoBox}>
          &#10006;
        </CloseIcon>
        <Title>About</Title>
        <About>
          <Text>
            Guess Clash Royale cards while playing different modes in a
            puzzle-like games. Can you correctly identify the cards and
            demonstrate your strategic knowledge?
          </Text>
        </About>
        <About>
          <Text>
            Clashdle was created under{" "}
            <a
              href="https://supercell.com/en/fan-content-policy/"
              target="blank"
            >
              Supercell's policy
            </a>
            . We are allowed to use Clash Royale assets under the fan content
            clause and we can use ads as one of the exceptions of monetization.
          </Text>
        </About>
        <Privacy>
          <Text>
            The website uses cookies to collect statistics and show ads. More
            info in the{" "}
            <a href="/privacy" target="blank">
              Privacy Policy
            </a>
            .
          </Text>
        </Privacy>
      </InfoContainer>
    </Box>
  );
};

export default InfoBox;
