import React, { useContext } from "react";

import { encryptXOR } from "../../../utils/encryptXor";

import { Game } from "../Game";
import { Card } from "../../../types/types";

import { GameWrapper } from "../GameWrapper";

import { GameContext } from "../../Providers";

import HeroBox from "../../HeroBox/HeroBox";

export const DescriptionGameComponent = () => {
  const { submittedCards, game } = useContext(GameContext);

  const isCardCorrect = (card: Card): boolean => {
    const secret = process.env.REACT_APP_XOR_ENCRYPTION_SECRET as string;
    const encryptedValue = encryptXOR(card.name, secret);
    return encryptedValue === game.hashedAnswer;
  };

  if (!("description" in game)) return null;

  return (
    <Game
      gameDescription="Which clash royale card has this description?"
      isCardCorrect={isCardCorrect}
      boxOptions={{ type: "description", description: game.description }}
    >
      {submittedCards.map((card, i) => (
        <HeroBox
          key={card.id}
          isCorrect={isCardCorrect(card)}
          name={card.name}
          imageSrc={card.image.url}
          shouldAnimate={i === 0}
          isInitial={!!card.isInitial}
        />
      ))}
    </Game>
  );
};

export const DescriptionGame = GameWrapper(DescriptionGameComponent, "description");