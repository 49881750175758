import styled from "styled-components";
import { theme } from '../../styles/Theme'

interface TextProps {
  primary?: boolean;
}

interface ContainerProps {
  centered?: boolean;
  margin?: "basic" | "none";
}

export const Container = styled.div<ContainerProps>`
  display: flex;
  justify-content: ${props => props.centered ? 'center' : ''};
  margin-top: ${props => props.margin === 'basic' ? '4.5rem' : '0'};
`

export const StyledTitle = styled.h1`
  color: white;
  font-family: "Supercell-Magic";
  font-size: 8rem;
  text-shadow: 0 0 0, 0 0 3rem ${theme.colors.black}, 0 0 0;
`

export const Text = styled.span<TextProps>`
  color: ${props => props.primary ? theme.colors.primary : theme.colors.white};
  font-family: inherit;
`