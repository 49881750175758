import styled from "styled-components";

import { theme } from "../../styles/Theme";

export const Nav = styled.nav`
  margin-top: 3rem;
`

export const NavItemContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0 2rem;
  cursor: pointer;
`

export const NavItemIcon = styled.div`
  height: 8rem;
  width: 8rem;
  background: ${theme.colors.white};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;

  img {
    width: 55%;
    height: 55%;
  }
`

export const NavItemText = styled.div`
  font-family: "Supercell-Magic";
  font-size: 3.5rem;
  color: ${theme.colors.white};
`

export const PrimaryText = styled.span`
  color: ${theme.colors.primary};
`