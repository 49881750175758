import styled from "styled-components";

import { theme } from '../../styles/Theme';

export const Container = styled.div`
  position: fixed;
  right: 5rem;
  bottom: 5rem;
  border: 3px solid ${theme.colors.primary};
  border-radius: 2rem;
  height: 20rem;
  width: 40rem;
  background: ${theme.colors.grey};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2.5rem;
  box-sizing: border-box;

  @media (max-width: 600px) {
    transform: translateX(-50%);
    right: unset;
    left: 50%;
    bottom: 10rem;
  }
`;

export const Paragraph = styled.p`
  text-align: center;
  color: ${theme.colors.blue};
  font-family: "Supercell-Magic";
  font-size: 1.4rem;
  line-height: 1.1;
`

export const AcknowledgeContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 3rem;
`

export const Button = styled.button`
  background: ${theme.colors.primary};
  text-transform: uppercase;
  color: ${theme.colors.blue};
  border: none;
  font-family: "Supercell-Magic";
  padding: 1.3rem 2.3rem;
  border-radius: 2rem;
  font-size: 2.4rem;
  cursor: pointer;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.35);
`

export const Icon = styled.div`
  img {
    width: 10rem;
  }
`