import React, { useState, useEffect, useRef } from "react";

import { IconBox } from "./styles";
import { FileType } from "../../types/types";

interface Props {
  audio: FileType;
}

const SoundPlayer = ({ audio }: Props) => {
  const [isPlayingSound, setIsPlayingSound] = useState<boolean>(false);
  const cardSound = useRef(new Audio(audio.url)).current;

  const toggleSound = () => {
    if (isPlayingSound) {
      cardSound.pause();
    } else {
      cardSound.currentTime = 0;
      cardSound.play();
    }

    setIsPlayingSound(!isPlayingSound);
  };

  useEffect(() => {
    const handleAudioEnded = () => {
      setIsPlayingSound(false);
    }

    cardSound.addEventListener("ended", handleAudioEnded);

    return () => {
      cardSound.removeEventListener("ended", handleAudioEnded);
      cardSound.currentTime = 0;
      cardSound.pause();
    }
  }, [cardSound])

  return (
    <IconBox iconSize="small">
      {isPlayingSound ? (
        <img onClick={toggleSound} src="/assets/pause_icon.svg" alt="ion" />
      ) : (
        <img onClick={toggleSound} src="/assets/sound_icon.svg" alt="ion" />
      )}
    </IconBox>
  );
};

export default SoundPlayer;
