import React from "react";

import { SwordContainer, Sword, Blade, Holder, Middle } from "./style";

export const SwordSpinner = () => {
  return (
    <SwordContainer>
      <Sword>
        <Blade>
          <Middle />
        </Blade>
        <Holder />
      </Sword>
    </SwordContainer>
  );
};
