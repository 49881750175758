import React from "react";

import {
  Box,
  Title,
  Hero,
  HeroImage,
  HeroName,
  NextGameInfo,
  NextGameLink,
} from "./style";

import GameTimer from "../GameTimer/GameTimer";
import { MTop } from "../../styles/Global";
import { Link } from "react-router-dom";

import { NextGameType } from "../../types/types";

interface Props {
  cardImage: string;
  cardName: string;
  nextGame?: NextGameType;
}

const GameVictory = ({ cardImage, cardName, nextGame }: Props) => {
  return (
    <Box>
      <Title>Victory!</Title>
      <Hero>
        <HeroImage>
          <img src={cardImage} alt="" />
        </HeroImage>
        <HeroName>{cardName}</HeroName>
      </Hero>
      <NextGameInfo>
        <span>Next game will be available in:</span>
        <MTop size="sm" />
        <GameTimer />
        {nextGame && (
          <NextGameLink>
            <Link to={nextGame.path}>{nextGame.name}</Link>
          </NextGameLink>
        )}
      </NextGameInfo>
    </Box>
  );
};

export default GameVictory;
