import React, { createContext, useEffect, useState } from "react";

import { PlayerContextInterface } from "../types";

import { PlayerInterface } from "../../../types/types";

import Fetcher from "../../../utils/Fetcher";

export const PlayerContext = createContext<PlayerContextInterface>({
  trophies: 0,
  isLoading: false,
  setPlayerContext: () => {},
});

export const PlayerProvider = ({ children }: { children: React.ReactNode }) => {
  const [playerContext, setPlayerContext] = useState<
    PlayerInterface & { isLoading: boolean }
  >({
    trophies: 0,
    isLoading: true,
  });

  useEffect(() => {
    const fetchData = async () => {
      const { trophies } = await Fetcher.fetchData<PlayerInterface>("/player");
      setPlayerContext({
        trophies,
        isLoading: false,
      });
    };

    fetchData();
  }, []);

  return (
    <PlayerContext.Provider value={{ ...playerContext, setPlayerContext }}>
      {children}
    </PlayerContext.Provider>
  );
};
