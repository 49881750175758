import React, { useState, useEffect, FormEvent, useContext, useRef } from "react";

import { GameContext, PlayerContext } from "../Providers";

import {
  Form,
  Button,
  StyledGameInput,
  Container,
  Box,
  SearchHints,
  SearchHintItem,
  SearchHintIcon,
  SearchHintTitle,
} from "./styles";
import { MTop } from "../../styles/Global";

import { Card } from "../../types/types";

import Fetcher from "../../utils/Fetcher";

interface GameInputProps {
  placeholder?: string;
  cards: Card[];
  isCardCorrect: (card: Card) => boolean;
}

const GameInput = ({ placeholder, cards, isCardCorrect }: GameInputProps) => {
  const [value, setValue] = useState<string>("");
  const [filteredCards, setFilteredCards] = useState<Card[]>([]);

  const { submittedCards, setSubmittedCards, setCorrectAnswer, correctAnswer, game, delayTime } = useContext(GameContext);
  const { setPlayerContext, ...playerContext } = useContext(PlayerContext);

  const gameInputElement = useRef<HTMLInputElement >(null);

  const [isInputFocused, setIsInputFocused] = useState<boolean>(false);

  const isAlreadySubmitted = (selectedCard: Card) => !!submittedCards.find(card => card.id === selectedCard.id);

  useEffect(() => {
    if (cards) {
      setFilteredCards(
        cards.filter((card) => {
          if (value === "" || isAlreadySubmitted(card)) {
            return false;
          } else {
            const cardWords = card.name.split(" ");
            let shouldBeShown = false;
            cardWords.forEach(cardWord => {
              if (!shouldBeShown)
                shouldBeShown = cardWord.toLowerCase().startsWith(value.toLowerCase())
            });
            return shouldBeShown || card.name.toLowerCase().startsWith(value.toLowerCase());
          }
        })
      );
    }
  }, [value, setFilteredCards, cards]);

  const submitAnswer = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!!correctAnswer) return;
    
    const selectedCard = filteredCards.find((card) => card.name.toLowerCase() === value.toLowerCase());
    if (!selectedCard || isAlreadySubmitted(selectedCard)) return;

    setSubmittedCards([selectedCard, ...submittedCards]);
    setValue("");

    if (isCardCorrect(selectedCard)) {
      Fetcher.postData("/game/finish", { gameTypeId: game.gameTypeId });
      setCorrectAnswer(selectedCard);
      setTimeout(() => {
        setPlayerContext({ ...playerContext, trophies: playerContext.trophies + 10 });
      }, delayTime)
    } else {
      Fetcher.postData("/game/wrong-answer", { gameTypeId: game.gameTypeId, cardId: selectedCard.id });
    }
  };

  const handleHintPressed = (hintValue: string) => {
    setValue(hintValue);
    gameInputElement.current?.focus();
  }

  return (
    <MTop size="sm">
      <Container>
        <Box>
          <Form onSubmit={submitAnswer}>
            <StyledGameInput
              disabled={!!correctAnswer}
              value={value}
              onChange={(e) => {
                setValue(e.target.value)
              }}
              placeholder={placeholder}
              ref={gameInputElement}
              onFocus={() => setIsInputFocused(true)}
              onBlur={() => setIsInputFocused(false)}
            />
            <Button type="submit" disabled={!!correctAnswer}>
              <img alt="arrow" src="/assets/arrow_button.svg" />
            </Button>
          </Form>
          {filteredCards.length !== 0 && isInputFocused && (
            <SearchHints>
              {filteredCards.map((item) => (
                <SearchHintItem key={item.id} onMouseDown={() => handleHintPressed(item.name)}>
                  <SearchHintIcon>
                    <img alt="card" src={item.image.url} />
                  </SearchHintIcon>
                  <SearchHintTitle>{item.name}</SearchHintTitle>
                </SearchHintItem>
              ))}            
            </SearchHints>
          )}
        </Box>
      </Container>
    </MTop>
  );
};

export default GameInput;
