import styled from "styled-components";

interface HideContainerProps {
  hide: boolean;
}

export const Container = styled.div`
  overflow-y: auto;
  background: url(/assets/background_full.jpg);
  background-position: bottom;
  background-attachment: fixed;
  background-size: cover;
  width: 100%;
  height: 100vh;
  z-index: 10;
  box-sizing: border-box;
  padding-bottom: 4rem;
  position: relative;

  @media (max-width: 600px) {
    background-attachment: local;
  }
`

export const BlackLayer = styled.div`
  position: fixed;
  height: 100%;
  width: 100%;
  background: rgba(0,0,0,0.4); 
  z-index: -10;

  @media (max-width: 600px) {
    background-attachment: local;
  }
`

export const TrophiesContainer = styled.div<HideContainerProps>`
  position: fixed;
  top: 2rem;
  left: 3rem;

  @media (max-width: 600px) {
    visibility: ${(props) => props.hide ? 'hidden' : 'visible'};
    bottom: 2rem;
    top: unset;
    left: 50%;
    transform: translateX(-50%);
  }
`

export const IconsContainer = styled.div`
  position: fixed;
  top: 2rem;
  right: 3rem;

  @media (max-width: 900px) {
    top: unset;
    right: 50%;
    bottom: 2rem;
    transform: translateX(50%);
  }

  @media (max-width: 600px) {
    bottom: 10rem;
  }

`

export const InfoBoxContainer = styled.div<HideContainerProps>`
  display: ${(props) => props.hide ? 'block' : 'none'};
  visibility: ${(props) => props.hide ? 'visible' : 'hidden'};
  position: absolute;
  height: 100%;
  width: 100%;
  background: ${(props) => props.hide ? 'rgba(0,0,0,0.5)' : 'rgba(0,0,0,0)'};;
`