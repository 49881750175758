export interface SizeProps {
  size: 'sm' | 'md' | 'bg' | 'hg';
}

export const theme = {
  colors: {
    primary: "#FFDF38",
    white: "#ffffff",
    grey: "#E0E0E0",
    black: "#000000",
    green: "#008C06",
    red: "#C10000",
    yellow: "#FFDF38",
    blue: "#2E6697",
    darkBlue: "#032E54"
  }
}

export enum Sizes {
  sm = '2rem',
  md = '4rem',
  bg = '8rem',
  hg = '12rem'
}