import React, { useContext, useEffect, useState } from "react";

import { Container, TrophiesIcon, TrophiesValue } from './style';

import { PlayerContext } from '../Providers';
import { Spinner } from "../Spinner";

import CountUp from 'react-countup';

const Trophies = () => {
  const { trophies, isLoading } = useContext(PlayerContext);

  const [end, setEnd] = useState(trophies);
  const [start, setStart] = useState(trophies);

  useEffect(() => {
    setEnd(trophies);
  }, [trophies])

  return (
    <Container>
      <TrophiesIcon>
        <img alt="trophies" src="/assets/trophy_icon.svg" />
      </TrophiesIcon>
      <TrophiesValue>
        {isLoading ? <Spinner /> : <CountUp start={start} end={end} onEnd={() => setStart(trophies)} />}
      </TrophiesValue>
    </Container>
  )
}

export default Trophies;