import { Buffer } from "buffer";

export const encryptXOR = (text: string, secret: string) => {
  const plaintextBuffer = Buffer.from(text, 'utf8');
  const secretKeyBuffer = Buffer.from(secret, 'utf8');

  for (let i = 0; i < plaintextBuffer.length; i++) {
    plaintextBuffer[i] ^= secretKeyBuffer[i % secretKeyBuffer.length];
  }

  return plaintextBuffer.toString('base64');
};
