import React, { useEffect } from "react";

import { GlobalStyles } from "./styles/Global";

import { Routes, Route, useLocation } from "react-router-dom";

import Home from "./components/Home";
import { ClassicGame, SoundGame, ImageGame, DescriptionGame } from './components/Games';
import Privacy from "./components/Privacy";

import { CardsProvider, PlayerProvider } from "./components/Providers";

import { sendPageView } from "./utils/gaTracking";

const App = () => {
  const location = useLocation();

  useEffect(() => {
    sendPageView(location.pathname + location.search)
  }, [location]);

  return (
      <PlayerProvider>
        <CardsProvider>
          <GlobalStyles />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/classic" element={ClassicGame} />
            <Route path="/sound" element={SoundGame} />
            <Route path="/image" element={ImageGame} />
            <Route path="/description" element={DescriptionGame} />
            <Route path="/privacy" element={<Privacy />} />
          </Routes>
        </CardsProvider>
      </PlayerProvider>
  );
};

export default App;
