import React from "react";

import {
  Nav,
  NavItemIcon,
  NavItemText,
  PrimaryText,
  NavItemContainer,
} from "./styles";

import { Link } from 'react-router-dom';

import { navItemsConfig } from "./config";

import { MTop } from '../../styles/Global';

const MainNav = () => {
  return (
    <Nav>
      {navItemsConfig.map((navItem) => (
        <Link key={navItem.link} to={navItem.link}>
          <MTop size="sm">
            <NavItemContainer>
              <NavItemIcon>
                <img src={navItem.iconPath} alt={navItem.alt} />
              </NavItemIcon>
              <NavItemText>
                {navItem.text
                  .split("")
                  .map((letter, i) =>
                    navItem.primaryColorLetters.includes(i) ? (
                      <PrimaryText key={i}>{letter}</PrimaryText>
                    ) : (
                      letter
                    )
                  )}
              </NavItemText>
            </NavItemContainer>
          </MTop>
        </Link>
      ))}
    </Nav>
  );
};

export default MainNav;
