import styled, { keyframes } from "styled-components";

const spin = keyframes`
 0% { transform: rotate(0); }
 40% { transform: rotate(720deg); }
 100% { transform: rotate(720deg); }
`;

export const SwordContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const Sword = styled.div`
  width: 5rem;
  height: 22rem;
  margin-top: 5rem;
  position: relative;
  animation: ${spin} 1.4s ease-in-out infinite;
  animation-delay: 0s, 0.8s;
`;

export const Blade = styled.div`
  height: 80%;
  width: 75%;
  margin: 0 auto;
  background-image: linear-gradient(to right, #b0bec5 50%, #cfd8dc 50%);
  position: relative;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.35);

  :before {
    content: "";
    position: absolute;
    top: -3.9rem;
    left: 0px;
    width: 0;
    height: 0;
    border-left: 1.9rem solid transparent;
    border-right: 0px solid transparent;
    border-bottom: 4rem solid #b0bec5;
  }

  :after {
    content: "";
    position: absolute;
    top: -3.9rem;
    right: 0px;
    width: 0;
    height: 0;
    border-left: 0 solid transparent;
    border-right: 1.9rem solid transparent;
    border-bottom: 4rem solid #cfd8dc;
  }
`;

export const Holder = styled.div`
  margin: 0 auto;
  width: 30%;
  height: 20%;
  background-color: #cfd8dc;
  border: 5px solid #b0bec5;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.35);

  :after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    width: 61%;
    height: 10%;
    background-color: #cfd8dc;
    border: 5px solid #b0bec5;
    border-radius: 8px;
    display: block;
    box-sizing: border-box;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.35);
  }
`;

export const Middle = styled.div`
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #cfd8dc;
  width: 150%;
  height: 6%;
  border-radius: 10px;
  border: 5px solid #b0bec5;
`;

const rotate = keyframes`
 100% { transform: rotate(360deg); } 
`;

const rotateDot = keyframes`
 80%, 100% { transform: rotate(360deg); } 
`;

const rotateDotBefore = keyframes`
 50% {
    transform: scale(0.4); 
  } 100%, 0% {
    transform: scale(1.0); 
  } 
`;

export const StyledSpinner = styled.div`
  width: 1.8rem;
  height: 1.8rem;
  position: relative;
  animation: ${rotate} 2.5s infinite linear both;
`;

export const SpinnerDot = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  animation: ${rotateDot} 2s infinite ease-in-out both;

  :before {
    content: "";
    display: block;
    width: 25%;
    height: 25%;
    background-color: #fff;
    border-radius: 100%;
    animation: ${rotateDotBefore} 2s infinite ease-in-out both;
  }

  :nth-child(1) {
    animation-delay: -1.1s;
  }
  :nth-child(2) {
    animation-delay: -1s;
  }
  :nth-child(3) {
    animation-delay: -0.9s;
  }
  :nth-child(4) {
    animation-delay: -0.8s;
  }
  :nth-child(5) {
    animation-delay: -0.7s;
  }
  :nth-child(6) {
    animation-delay: -0.6s;
  }
  :nth-child(1):before {
    animation-delay: -1.1s;
  }
  :nth-child(2):before {
    animation-delay: -1s;
  }
  :nth-child(3):before {
    animation-delay: -0.9s;
  }
  :nth-child(4):before {
    animation-delay: -0.8s;
  }
  :nth-child(5):before {
    animation-delay: -0.7s;
  }
  :nth-child(6):before {
    animation-delay: -0.6s;
  }
`;
