import { getConfig } from "../config";
import axios from "axios";
import { setupCache } from 'axios-cache-adapter'

const cache = setupCache({
  maxAge: 15 * 60 * 1000
})

const api = axios.create({
  adapter: cache.adapter
})

class Fetcher {
  proxy: string;
  commonHeaders: Record<string, string>;

  constructor() {
    const config = getConfig();
    this.proxy = config.apiUrl;
    this.commonHeaders = {
      "api-key": config.apiKey
    };
  }

  fetchData = async <T>(route: string, cacheEnabled: boolean = false): Promise<T> => {
    try {
      const cacheConfig = cacheEnabled ? { maxAge: 15 * 60 * 1000 } : { maxAge: 0 };
      const response = await api.get<T>(`${this.proxy}${route}`, {
        cache: cacheConfig,
        headers: this.commonHeaders
      });
      return response.data;
    } catch (err) {
      throw err;
    }
  };

  postData = async <T>(
    route: string,
    body: any,
  ): Promise<T> => {
    try {
      const response = await api.post<T>(
        `${this.proxy}${route}`,
        body,
        {
          headers: this.commonHeaders
        }
      );
      return response.data;
    } catch (err) {
      throw err;
    }
  };

  deleteData = async <T>(route: string): Promise<T> => {
    try {
      const response = await api.delete<T>(`${this.proxy}${route}`, { headers: this.commonHeaders });
      return response.data;
    } catch (err) {
      throw err;
    }
  };

  patchData = async <T>(route: string, body: any): Promise<T> => {
    try {
      const response = await api.patch<T>(
        `${this.proxy}${route}`,
        body,
        { headers: this.commonHeaders }
      );
      return response.data;
    } catch (err) {
      throw err;
    }
  };
}

const fetcherInstance = new Fetcher();

export default fetcherInstance;
