import ReactGA from 'react-ga4';
import { EnvironmentEnum } from '../types/config';

export const sendPageView = (page: string) => {
    if(process.env.REACT_APP_ENVIRONMENT === EnvironmentEnum.prod) {
        ReactGA.send({
            hitType: 'pageview',
            page,
        })
    }
}