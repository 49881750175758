import React from 'react';

import GoogleAd from './GoogleAd';

import { BottomFixedContainer } from './style';

const AdBottomFixed = () => {
  return (
    <BottomFixedContainer>
      <GoogleAd />
    </BottomFixedContainer>
  )
}

export default AdBottomFixed;