import React from "react";

const Privacy = () => {
  return (
    <div style={{ fontSize: '16px' }}>
      <h1>Privacy Policy</h1>

      <p>
        This Privacy Policy outlines how we collect, use, and protect the
        information you provide when using our website. By using our website,
        you agree to the collection and use of your information as described in
        this policy.
      </p>

      <h2>Information Collection and Use</h2>

      <p>
        We use Google Analytics to collect information about your visits to our
        website. This information may include your IP address, browser type,
        referring/exit pages, operating system, date/time stamps, and
        clickstream data. We use this information to analyze website traffic,
        understand user behavior, and improve the overall user experience. This
        data is collected using cookies and similar tracking technologies. We
        also use Google AdSense to serve targeted advertisements on our website.
        Google AdSense may collect certain information about your browsing
        behavior and interests to personalize the ads you see. This information
        is collected using cookies and similar technologies.
      </p>

      <h2>Data Sharing</h2>

      <p>
        We may share the information collected through Google Analytics and
        Google AdSense with third parties for analytics and advertising
        purposes. These third parties have their own privacy policies that
        govern the use of your information.
      </p>

      <h2>Cookies and Tracking Technologies</h2>

      <p>
        Our website uses cookies and similar tracking technologies to enhance
        your browsing experience. Cookies are small files stored on your device
        that allow us to remember certain information and provide personalized
        features. You can manage or disable cookies through your browser
        settings. Please note that disabling cookies may affect the
        functionality of our website.
      </p>

      <h2>Opt-Out Options</h2>

      <p>
        You have the option to opt out of personalized ads or adjust your ad
        preferences through the tools provided by Google or other ad networks.
        Please refer to the privacy settings of your browser and the specific ad
        network's website for more information on how to exercise your choices.
      </p>

      <h2>Data Security</h2>

      <p>
        We take reasonable measures to protect the information collected through
        our website. However, please note that no data transmission over the
        internet or electronic storage method is 100% secure. While we strive to
        use commercially acceptable means to protect your information, we cannot
        guarantee absolute security.
      </p>

      <h2>Data Retention</h2>

      <p>
        We retain the information collected through Google Analytics and Google
        AdSense for as long as necessary to fulfill the purposes outlined in
        this privacy policy. Please refer to Google's data retention policies
        for further information.
      </p>

      <h2>Third-Party Websites</h2>

      <p>
        Our website may contain links to third-party websites. Please note that
        our privacy policy does not apply to those sites. We encourage you to
        review the privacy policies of any third-party websites you visit.
      </p>

      <h2>Updates to the Privacy Policy</h2>

      <p>
        We may update our privacy policy from time to time. Any changes will be
        posted on this page with a revised "Last Updated" date. We encourage you
        to review this policy periodically for any updates or changes.
      </p>

      <h2>Contact Us</h2>

      <p>
        If you have any questions or concerns about our privacy policy or data
        practices, please contact us at stranweb@gmail.com.
      </p>
    </div>
  );
};

export default Privacy;
