import React from 'react';

import Layer from '../Layer';
import MainNav from '../MainNav';
import CookieBanner from '../CookieBanner';
import AdStandard from '../GoogleAd/AdStandard';

import { Container } from './styles';

const Home = () => {
  return (
    <Layer>
      <Container>
        <MainNav />
      </Container>
      <CookieBanner />
    </Layer>
  )
}

export default Home;