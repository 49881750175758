import styled from "styled-components";

import { theme } from "../../styles/Theme";

const mapIconSize = (iconSize: string) => {
  switch (iconSize) {
    case "small":
      return "20%";
    case "regular":
      return "40%";
    default:
      return "100%";
  }
};

interface IconBoxProps {
  iconSize: string;
}

interface BlurredImageProps {
  blurAmount: number;
}

interface ButtonProps {
  disabled: boolean;
}

export const Container = styled.div`
  display: flex;
  justify-content: center;
`;

export const Box = styled.div`
  position: relative;
  width: 50rem;
`;

export const InfoBox = styled.div`
  padding: 3rem;
  border: 5px solid ${theme.colors.primary};
  background: rgba(245, 245, 245, 0.5);
  border-radius: 1.5rem;
`;

export const IconBox = styled.div<IconBoxProps>`
  display: flex;
  justify-content: center;
  margin-top: 2rem;

  img {
    width: ${(props) => mapIconSize(props.iconSize)};
    cursor: pointer;
  }
`;

export const Paragraph = styled.p`
  margin-top: 3rem;
  color: white;
  font-family: "Supercell-Magic";
  padding: 0 5rem;
  text-align: center;
  font-size: 1.8rem;
  line-height: 1.2;
`;

export const InfoBoxText = styled.h2`
  font-family: "Supercell-Magic";
  font-size: 3.2rem;
  text-align: center;
  font-weight: normal;
  line-height: 1.2;
  color: ${theme.colors.white};
`;

export const StyledGameInput = styled.input`
  box-sizing: border-box;
  width: 100%;
  border-radius: 0.5rem;
  border: 3px solid ${theme.colors.primary};
  padding: 1.8rem 1.5rem;
  font-family: "Supercell-Magic";
  font-weight: normal;
  font-size: 1.7rem;
  color: ${theme.colors.black};
  outline: none;
  background: ${theme.colors.white};

  &:disabled {
    background: ${theme.colors.grey};
  }

  &::placeholder {
    color: rgba(46, 102, 151, 0.5);
  }
`;

export const Form = styled.form`
  display: flex;
  gap: .7rem;
`

export const Button = styled.button<ButtonProps>`
  width: 7rem;
  border: 3px solid ${theme.colors.primary};
  border-radius: 0.5rem;
  cursor: pointer;
  background: ${(props) => props.disabled ? theme.colors.grey : theme.colors.white};

  img {
    width: 70%;
  }
`

export const SearchHints = styled.div`
  position: absolute;
  z-index: 100;
  margin-top: 0.3rem;
  width: 100%;
  background: ${theme.colors.white};
  border-radius: 0.5rem;
  max-height: 30rem;
  overflow-y: auto;

  ::-webkit-scrollbar {
    width: 10px;
  }

  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  ::-webkit-scrollbar-thumb {
    background: #888;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;

export const SearchHintItem = styled.div`
  display: flex;
  align-items: center;
  padding: 1rem;
  cursor: pointer;

  &:hover {
    background: ${theme.colors.grey};
  }
`;

export const SearchHintIcon = styled.div`
  img {
    width: 5rem;
  }
`;

export const SearchHintTitle = styled.h4`
  font-family: "Supercell-Magic";
  font-weight: normal;
  font-size: 1.8rem;
  margin-left: 1.3rem;
  color: ${theme.colors.black};
`;

export const BlurredImage = styled.div<BlurredImageProps>`
  text-align: center;
  margin-top: 3rem;

  img {
    width: 17rem;
    opacity: 0.9;
    filter: ${(props) => `blur(${props.blurAmount}px) ${props.blurAmount === 0 ? "" : "grayscale(20%) saturate(2)"}`};
  }
`