import styled from "styled-components";

export const BottomFixedContainer = styled.div`
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 10rem;
`;

export const Container = styled.div`
  width: 25rem;
  height: 25rem;
`;
