import React, { useState, useEffect } from "react";

import { getSecondsElapsedUntilReset } from "../../utils/getSecondsElapsedUntilReset";

import { GameTimerContainer } from "./style";

const formatTimer = (distance: number) => {
  let hours = Math.floor(distance / (1000 * 60 * 60));
  let minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
  let seconds = Math.floor((distance % (1000 * 60)) / 1000);

  return `${hours.toString().padStart(2, "0")}:${minutes.toString().padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
};

const GameTimer = () => {
  const [secondsElapsed, setSecondsElapsed] = useState<number>(getSecondsElapsedUntilReset());
  const [timerId, setTimerId] = useState<ReturnType<typeof setInterval>>();

  useEffect(() => {
    const timer = setInterval(
      () => setSecondsElapsed((prevCount) => prevCount - 1),
      1000
    );
    setTimerId(timer);
    return () => clearInterval(timer);
  }, []);

  useEffect(() => {
    if (secondsElapsed === 0) {
      clearInterval(timerId);
    }
  }, [secondsElapsed, timerId]);

  return (
    <GameTimerContainer>
      {formatTimer(secondsElapsed * 1000)}
    </GameTimerContainer>
  );
};

export default GameTimer;
