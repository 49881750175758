import React from "react";

import {
  Container,
  HeroImageContainer,
  HeroName,
  Box,
  BoxAnimated,
} from "./styles";

interface HeroBoxProps {
  isCorrect: boolean;
  imageSrc: string;
  name: string;
  shouldAnimate: boolean;
  isInitial: boolean;
}

const HeroBox = ({
  isCorrect,
  imageSrc,
  name,
  shouldAnimate,
  isInitial
}: HeroBoxProps) => {
  return (
    <Container>
      {shouldAnimate && !isInitial ? (
        <BoxAnimated isCorrect={isCorrect}>
          <HeroImageContainer>
            <img src={imageSrc} alt={name} />
          </HeroImageContainer>
          <HeroName>{name}</HeroName>
        </BoxAnimated>
      ) : (
        <Box isCorrect={isCorrect}>
          <HeroImageContainer>
            <img src={imageSrc} alt={name} />
          </HeroImageContainer>
          <HeroName>{name}</HeroName>
        </Box>
      )}
    </Container>
  );
};

export default HeroBox;
