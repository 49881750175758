import React, { createContext, useEffect, useState } from 'react';

import { CardContextInterface } from '../types';

import { Card } from '../../../types/types';

import Fetcher from '../../../utils/Fetcher';

export const CardsContext = createContext<CardContextInterface>({
  cards: [],
  isLoading: false
});

export const CardsProvider = ({
  children
}: { children: React.ReactNode }) => {
  const [cardsContext, setCardsContext] = useState<CardContextInterface>({
    cards: [],
    isLoading: true
  })

  useEffect(() => {
    const fetchData = async () => {
      const cards = await Fetcher.fetchData<Card[]>("/card", true);
      setCardsContext({
        cards,
        isLoading: false
      });
    }

    fetchData();
  }, [])

  return (
    <CardsContext.Provider value={cardsContext}>
      {children}
    </CardsContext.Provider>
  )
}
