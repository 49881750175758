import React, { useState, useRef } from "react";

import {
  Container,
  BlackLayer,
  TrophiesContainer,
  IconsContainer,
  InfoBoxContainer,
} from "./styles";

import Title from "../Title";
import Trophies from "../Trophies/Trophies";
import { InfoIcons, InfoBox } from "../Info";

interface LayerProps {
  children: React.ReactNode;
  withTitle?: boolean;
}

const Layer = ({ children, withTitle = true }: LayerProps) => {
  const [isInfoBoxOpen, setInfoBoxOpen] = useState<boolean>(false);
  const blackLayerBox = useRef(null);

  const toggleInfoBox = () => {
    setInfoBoxOpen(!isInfoBoxOpen);
  };

  const blackLayerPress = (e: React.MouseEvent<HTMLElement>) => {
    if (e.target === blackLayerBox.current) {
      toggleInfoBox();
    }
  };

  return (
    <Container>
      <InfoBoxContainer
        ref={blackLayerBox}
        onClick={blackLayerPress}
        hide={isInfoBoxOpen}
      >
        <InfoBox toggleInfoBox={toggleInfoBox} visible={isInfoBoxOpen} />
      </InfoBoxContainer>
      <BlackLayer />
      <TrophiesContainer hide={window.location.pathname !== "/"}>
        <Trophies />
      </TrophiesContainer>
      {window.location.pathname === "/" && (
        <IconsContainer>
          <InfoIcons toggleInfoBox={toggleInfoBox} />
        </IconsContainer>
      )}
      {withTitle && <Title centered margin="basic" />}
      {children}
    </Container>
  );
};

export default Layer;
