import React from 'react';

import InfoIcon from './InfoIcon';

import { Container } from './style';

interface Props {
  toggleInfoBox: () => void;
}

const InfoIcons = ({ toggleInfoBox }: Props) => {

  const handleDiscordIconClick = () => {
    window.open("https://discord.gg/9W9kCNA", "_blank", "noreferrer");
  }

  const handleCoffieIconClick = () => {
    window.open("https://ko-fi.com/clashdle", "_blank", "noreferrer");
  }

  const handleInfoIconClick = () => {
    toggleInfoBox();
  }

  return (
    <Container>
      <InfoIcon path="/assets/discord_icon.svg" onClick={handleDiscordIconClick} />
      <InfoIcon path="/assets/coffie_icon.svg" onClick={handleCoffieIconClick} />
      <InfoIcon path="/assets/info_icon.svg" onClick={handleInfoIconClick} />
    </Container>
  )
}

export default InfoIcons;