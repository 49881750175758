export interface NavItemConfig {
  iconPath: string;
  alt?: string;
  text: string;
  primaryColorLetters: number[];
  link: string;
}

export enum EnvironmentEnum {
  dev = "dev",
  staging = 'staging',
  prod = 'prod'
}